import React, { Component } from 'react';
import {
    Row, Col
} from 'reactstrap';
import styles from './ClaimSummary.module.css';
import classNames from 'classnames';
import PhoneIcon from '../../../../assets/images/Vendor/Apple/phone.png'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent'
import { phoneFormat } from '../../../shared/util'
import InternationalPhoneStatic from '../../../shared/PhoneNumberUtility/InternationalPhoneStatic'
import InternationalPhoneWithCode from '../../../shared/PhoneNumberUtility/InternationalPhoneWithCode'
import { StaticAddress } from './CountryAddressForms';
import { callAdobe, countryNameFromCode, translateIncidentType } from '../../../../helpers';
import CallLink from '../../../shared/PhoneNumberUtility/CallLink';
import { appleServiceNumbers } from '../../../_BrandingProvider/brandContent';
import VisaIcon from '../../../../assets/images/cc-visa.svg';
import MasterCardIcon from '../../../../assets/images/cc-mastercard.svg';
import AmexIcon from '../../../../assets/images/cc-amex.svg';
import DiscoverIcon from '../../../../assets/images/cc-discover.svg';


let col8 = "col-8"
let col4 = "col-4"
let colMd9 = "col-md-9";
let colMd8 = "col-md-8";
let colMd3 = "col-md-3";
let blue = styles.blue;
let h4 = "h4";
let mb0 = "mb-0";
let mt0 = "mt-0";
let pr15 = styles.pr15;

export default class ClaimSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: this.props.step,
            coverageName: '',
            contractAsset: null,
            ccNumber: this.props.ccNumber,
            ccType: this.props.ccType,
            entitlementDetails: this.props.entitlementDetails,
            claim: ''
        }
        this.getCCType = this.getCCType.bind(this);
        this.VerifyInfoRedirect = this.VerifyInfoRedirect.bind(this);
    }

    componentWillMount() {
        console.log(this.props.entitlementDetails);
        if (typeof this.props.entitlementDetails !== "undefined" && this.props.entitlementDetails.coverageId != "") {
            var coverageId = this.props.entitlementDetails.coverageId;
            fetch('api/contract/GetCoverageByContractAssetCoverageId/'.concat(coverageId).concat('/').concat(this.props.entitlementDetails?.contract?.contractAssetModel[0]?.contractAssetId).concat('/apple'))
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        coverageName: data.coverageName
                    })
                    // FOR ADOBE ANALYTICS
                    callAdobe({
                        claimType: data?.coverageName
                    })
                });
            this.setState({ entitlementDetails: this.props.entitlementDetails })
        }

        this.setState({ ccNumber: this.props.ccNumber === undefined ? "" : this.props.ccNumber, claim: this.props.claim === undefined ? {} : this.props.claim, ccType: this.props.ccType === undefined ? "" : this.props.ccType })
    }

    VerifyInfoRedirect = e => {
        e.preventDefault();
        this.props.history.push('/vendor/apple/verifyinfo', {
            entitlementDetails: this.state.entitlementDetails, claim: this.state.claim, fromPayment: true
        });
    }

    getCCType = () => {

        switch (this.state.ccType) {
            case "visa":
                return (<img className={styles.ccImage} alt="Visa" src={VisaIcon} />)
            case "mastercard":
                return (<img className={styles.ccImage} alt="mastercard" src={MasterCardIcon} />)
            case "amex":
                return (<img className={styles.ccImage} alt="Amex" src={AmexIcon} />)
            case "discover":
                return (<img className={styles.ccImage} alt="Discover" src={DiscoverIcon} />)
            default:
                return (<img className={styles.ccImage} alt="Unidentified" />)
        }
    }



    render() {
        let rtCol = styles.rtCol;
        let btmLine = styles.btmLine;
        let total = styles.total;
        let colLg5 = "col-lg-5";
        let configDescriptionKey = "2";


        let contract = this.props.entitlementDetails.contract;
        let contractAsset = typeof this.props.entitlementDetails.contractAsset === "undefined" ? new Object() : this.props.entitlementDetails.contractAsset;
        let riskProfile = typeof contract === "undefined" ? new Object() : contract.riskProfile.riskProfileConfigurationModel;
        let deductible = typeof contract === "undefined" ? 199 : riskProfile.find(item => { return item.pricingAttributeId === 19 }).value;
        let pretaxDeductible = typeof deductible === "undefined" ? 0 : deductible;
        let taxAmount = 0;
        let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
        if (countryCode.toLowerCase() == "aus") {
            let taxPercentage = typeof contract === "undefined" ? 0 : riskProfile.find(item => { return item.pricingAttributeId === 35 })?.value ?? 0;
            taxAmount = parseFloat(parseFloat(pretaxDeductible) * (parseInt(taxPercentage) / 100)).toFixed(2)
            this.props.entitlementDetails.deductible = parseFloat((parseFloat(pretaxDeductible) + parseFloat(taxAmount))).toFixed(2);
        };
        let model = typeof contractAsset.assetConfigDictionary === "undefined" || contractAsset.assetConfigDictionary === null || typeof contractAsset.assetConfigDictionary[configDescriptionKey] === "undefined" || Object.keys(contractAsset.assetConfigDictionary).length === 0 ? null : contractAsset.assetConfigDictionary[configDescriptionKey];

        let validModel = model !== undefined && model != null && model.length > 0;
        let validSerial = contractAsset.serialNumber !== undefined && contractAsset.serialNumber != null && contractAsset.serialNumber.length > 0;

        const currencySuffix = (country, symbol) => {
            switch (country) {
                case 'AT':
                case 'DK':
                case 'FI':
                case 'NL':
                case 'NO':
                case 'SE':
                case 'CH':
                case 'DE':
                case 'UK':                
                case 'IT':
                case 'ES':
                case 'FR':
                case 'VN':
                case 'CL':
                    return `${symbol}`
                default:
                    return ''
            }
        }

        const staticAddressDisplay = {
            firstName: this.props.entitlementDetails?.party?.firstName,
            lastName: this.props.entitlementDetails?.party?.lastName,
            address1: this.props.entitlementDetails?.party?.shippingAddress?.address1,
            address2: this.props.entitlementDetails?.party?.shippingAddress?.address2,
            city: this.props.entitlementDetails?.party?.shippingAddress?.city,
            state: this.props.entitlementDetails?.party?.shippingAddress?.state || '',
            stateId: this.props.entitlementDetails?.party?.shippingAddress?.stateId,
            zip: this.props.entitlementDetails?.party?.shippingAddress?.zip,
            country: this.props.entitlementDetails?.party?.shippingAddress?.countryCode
        }
        const { email, contactPhone, incidentDate } = this.props.entitlementDetails

        // console.log("=== contactPhone ===", contactPhone)
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/IntakeLoss/ClaimSummary'}>
                {international => {
                    const {
                        country,
                        language,
                        countryVars: {
                            currency: {
                                symbol,
                                thousandsDelimiter,
                                decimalDelimiter
                            },
                            date: {
                                momentJsFormat
                            }
                        },
                        englishVars: {
                            deductibleName
                        },
                        translations
                    } = international;

                    return (
                        <article className={classNames(colLg5, rtCol)}>
                            <h2 className={classNames("h4", btmLine)}>{translations.title || 'Claim Summary'}</h2>
                            {this.state.step >= 1 && validModel && validSerial &&
                                <div className={styles.btmLine}>
                                    <div className={styles.deviceInfo}>
                                        <h3 className="h6 mb-1">{translations.claimedDevice || 'Claimed Device'}</h3>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-4">{translations.model || 'Model'}:</span>
                                            <span className={classNames(col8, colMd8, pr15)}>{model}</span>
                                        </Row>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-4">{translations.serial || 'Serial'}:</span>
                                            <span className={classNames(col8, colMd8, pr15)}>{contractAsset.serialNumber}</span>
                                        </Row>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-4">{translations.deductible || deductibleName}:</span>
                                            <span className={classNames(col8, colMd8, pr15, blue)}>
                                                <NumberFormat
                                                    value={country === 'AUS' ? pretaxDeductible : this.props.entitlementDetails.deductible}
                                                    displayType={'text'}
                                                    thousandSeparator={thousandsDelimiter}
                                                    decimalSeparator={decimalDelimiter}
                                                    prefix={(country == 'IE' || country == 'NZ' || country == 'US' || country == 'CL' || country == 'AUS') && symbol}
                                                    suffix={currencySuffix(country, symbol)}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </span>
                                        </Row>
                                        <img className={styles.deviceImg} src={PhoneIcon} role="presentation" />
                                    </div>
                                    <p>
                                        {translations.wrongDevice || `Incorrect device? Call Apple at `}
                                        <CallLink phone={appleServiceNumbers.incorrectDevice[country]} ariaLabel={translations.incorrectDeviceAria || `Click to call ${appleServiceNumbers.incorrectDevice[country]}`} />.
                                        {/* <a className="callLink" aria-label="Click to call 800-275-2273" href="tel:800-275-2273">
                                            <InternationalPhoneStatic number={'(800) 275-2273'} />
                                        </a>. */}
                                    </p>
                                </div>
                            }

                            <h3 className="h4 mb-0">{translations.whatHappened || 'What Happened'}</h3>
                            <div className={styles.btmLine}>
                                {this.state.step >= 2 && this.props.entitlementDetails !== "undefined" &&
                                    <>
                                        <Row className="no-gutters mt-3">
                                            <span className="col-4 col-md-4">{translations.incidentType || 'Incident'}:</span>
                                            <span className="col-8 col-md-8">{translateIncidentType(this.state.coverageName, language)}</span>
                                        </Row>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-4">{translations.dateTitle || 'Date'}:</span>
                                            <span className="col-8 col-md-8">
                                                {typeof this.props.entitlementDetails !== "undefined" &&
                                                    moment(incidentDate).format(momentJsFormat)
                                                }
                                            </span>
                                        </Row>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-4">{translations.description || 'Description'}:</span>
                                            <span className={classNames("col-8 col-md-8", styles.wrapText)}>
                                                {typeof this.props.entitlementDetails !== "undefined" &&
                                                    this.props.entitlementDetails.incidentDescription
                                                }
                                            </span>
                                        </Row>
                                    </>
                                }
                            </div>
                            {this.state.step === 3 ? (
                                <Row className="no-gutters">
                                    <h3 className={classNames(col8, h4, mb0)}>{translations.shippingDetails || 'Shipping Details'}</h3>
                                    <a href="" onClick={this.VerifyInfoRedirect} className="col-4 btnLink text-right" aria-label={translations.editAria || "Click edit to go back to Shipping Details step and edit entered information."}>
                                        {translations.edit || 'Edit'}
                                    </a>
                                </Row>
                            ) : (
                                <h3 className="h4 mb-0">{translations.shippingDetails || 'Shipping Details'}</h3>
                            )}
                            <div className={styles.btmLine}>
                                {this.state.step >= 3 &&
                                    <>
                                        <Row className="no-gutters mt-3">
                                            <span className="col-4 col-md-4">{translations.shipTo || 'Ship To'}:</span>
                                            <span className="col-8 col-md-8">
                                                <StaticAddress
                                                    showName
                                                    showCountry={country.toLowerCase() !== this.props.entitlementDetails.shipToCountryCode}
                                                    addressData={staticAddressDisplay}
                                                    countryCode={this.props.entitlementDetails?.shipToCountryCode}
                                                    states={this.state.states}
                                                    clientId={this.props.entitlementDetails?.clientId}
                                                />
                                            </span>
                                        </Row>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-4">{translations.contact || 'Contact'}:</span>
                                            <span className="col-8 col-md-8">
                                                <ul className="noBullets" role="none">
                                                    <li>
                                                        {typeof this.props.entitlementDetails !== 'undefined' && !!contactPhone && <InternationalPhoneWithCode number={contactPhone} />}
                                                        {/* COMMENTING UNTIL TESTING COMPLETE TO ENSURE FUNCTIONALITY - NPB 07/02/2021
                                                            <NumberFormat format="(###) ###-####" displayType="text" value={typeof this.props.entitlementDetails === "undefined" ? 1234567890 : this.props.entitlementDetails.contactPhone} /> 
                                                        */}
                                                    </li>
                                                    <li>{email}</li>
                                                </ul>
                                            </span>
                                        </Row>
                                    </>
                                }
                            </div>

                            <div className={styles.btmLine}>
                                {country === 'AUS' ? (
                                    <div className={styles.deviceInfo}>
                                        <h2 className="h4 mb-1">Payment Details</h2>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-3">{deductibleName}:</span>
                                            <NumberFormat
                                                className="col-8 col-md-9 text-right"
                                                value={pretaxDeductible}
                                                displayType={'text'}
                                                thousandSeparator={thousandsDelimiter}
                                                decimalSeparator={decimalDelimiter}
                                                prefix={(country == 'IE' || country == 'NZ' || country == 'US' || country == 'AUS') && symbol}
                                                suffix={currencySuffix(country, symbol)}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </Row>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-3">GST:</span>
                                            <NumberFormat
                                                className="col-8 col-md-9 text-right"
                                                value={typeof taxAmount === "undefined" ? 0 : taxAmount}
                                                displayType={'text'}
                                                decimalSeparator={decimalDelimiter}
                                                thousandSeparator={thousandsDelimiter}
                                                prefix={(country == 'IE' || country == 'NZ' || country == 'US' || country == 'AUS') && symbol}
                                                suffix={currencySuffix(country, symbol)}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </Row>
                                        <Row className="no-gutters">
                                            <span className="col-4 col-md-5"><b>Total Payment:</b></span>
                                            <NumberFormat
                                                className={classNames(col8, 'col-md-7 text-right', blue)}
                                                value={typeof this.props.entitlementDetails === "undefined" ? 0 : this.props.entitlementDetails.deductible}
                                                displayType={'text'}
                                                decimalSeparator={decimalDelimiter}
                                                thousandSeparator={thousandsDelimiter}
                                                prefix={(country == 'IE' || country == 'NZ' || country == 'US' || country == 'AUS') && symbol}
                                                suffix={currencySuffix(country,symbol)}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </Row>
                                    </div>
                                ) : (
                                    <Row className={styles.totalPaymentContainer}>
                                        <h3 className={classNames(h4, mb0)}>{translations.totalPayment || 'Total Payment'}</h3>
                                        <p className={classNames(total, h4, mt0, styles.totalValue, 'text-right')}>
                                            <NumberFormat
                                                value={typeof this.props.entitlementDetails === "undefined" ? 0 : this.props.entitlementDetails.deductible}
                                                displayType={'text'}
                                                decimalSeparator={decimalDelimiter}
                                                thousandSeparator={thousandsDelimiter}
                                                prefix={(country == 'IE' || country == 'NZ' || country == 'US' || country == 'CL' ) && symbol}
                                                suffix={currencySuffix(country, symbol)}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </p>
                                    </Row>
                                )}
                                <Row>
                                    {this.state.step >= 4 &&
                                        <>
                                            <span className="col-12 mt-3">{this.getCCType()}{translations.endingWith || 'ending with '} {this.state.ccNumber}</span>
                                            <span className="col-12">{translations.cardNotChargedUntil || 'Card will not be charged until claim is approved.'}</span>
                                        </>
                                    }
                                </Row>
                            </div>
                        </article>
                    )
                }}
            </UseTranslationInClassComponent>
        );
    }
}