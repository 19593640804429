import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Col } from 'reactstrap';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { updatePartnerCase, updateRepair, cancelClaim, addTUStatus, getTUToken, getTUCaseId, isAppleRoute } from '../../helpers';
import FooterButtonsRow from '../shared/FooterButtonsRow/FooterButtonsRow';
import useTranslation from '../../components/contexts/LanguageTranslation/useTranslation';
import { DropDownInput } from '../../components/inputs/DropDownInput';
import styles from './SharedStyles.module.css';

const CancelModal = ({
    isOpen,
    claimNumber,
    caseNumber,
    handleCancel,
    onSaveForLaterAction,
    onCloseModal,
    isSubmittedClaim = false,
    isUploadDocs = false,
    isClaimOverview = false,
    clientId
    // ariaLabel
}) => {
    const location = useLocation()
    const history = useHistory()
    const international = useTranslation('components/modals/CancelModal');
    const { country, language, translations, englishVars } = international;
    const [cancelOption, setCancelOption] = useState();
    const [cancelReason, setCancelReason] = useState([]);
    const [englishCancelReason, setEnglishCancelReason] = useState([]);
    const [isCancelOptionError, setIsCancelOptionError] = useState(false);

    const clientIdCode = clientId ?? location.state?.entitlementDetails?.clientId;

    const translateCancelReasons = () => {
        /*Copy cancelReason array using map instead of spread operator to avoid making changes to 
          cancelReason state variable due to shallow copying*/
        let cancelReasons = cancelReason.map(reason =>( {...reason}));

        cancelReasons.map((reasons) => {
            switch (reasons.display) {

                case 'I found my device.':
                    reasons.display = translations.cancelReason1 || reasons.display
                    break;

                case 'I do not want to pay the deductible.':
                    reasons.display = translations.cancelReason2 || reasons.display
                    break;

                case 'I do not want to pay the  Excess Fee':
                    reasons.display = translations.cancelReason3 || reasons.display
                    break;

                case 'My device is damaged (not lost/stolen).':
                    reasons.display = translations.cancelReason4 || reasons.display
                    break;

                default:
                    return;

            }
        })
        return cancelReasons;
    }

    useEffect(() => {
        setCancelOption();
        fetchCancelReasons();
    }, [])
    const fetchCancelReasons = async () => {
        let claimActionTypeId = 2;
        await fetch('api/claim/GetClaimActionReason/' + clientIdCode + '/' + claimActionTypeId)
            .then(res => res.json())
            .then(data => {
                setEnglishCancelReason(data);
                let results = (data.map(v => { return { data: v.claimActionReasonId, display: v.actionReasonName, type: v.claimActionTypeId } }))
                let cancelReasons = results.filter(x => x.type === 2);
                let displayText = cancelReasons.find(y => y.data === 35)?.display + ' ' + englishVars.deductibleName;
                setCancelReason(cancelReasons.map((p) => p.data === 35 ? { ...p, display: displayText } : p));
            });
    }
    const handleCancelReason = (e) => {
        setIsCancelOptionError(false);
        setCancelOption(e)
    }

    const onResumeClick = (e) => {
        setCancelOption();
        setIsCancelOptionError(false);
        onCloseModal(e);
    }

    const onCancelClick = (e) => {
        if (!!handleCancel && clientIdCode != "apple") {
            handleCancel();
            return;
        }

        e.preventDefault();
        if (!cancelOption) {
            setIsCancelOptionError(true);
            return;
        }
        const entitlementDetails = location.state.entitlementDetails;
        const claim = location.state.claim;
        claimNumber = clientIdCode !== "apple" ? claimNumber : claimNumber === (undefined || true || false) ? location.state?.claimId : claimNumber;
        updateRepair("apple", claimNumber, "CCCR")
            .then(res => {
                cancelClaim("apple", claimNumber, false);
                updatePartnerCase(claimNumber, "CCCR", "apple");

                if (entitlementDetails != undefined) {
                    //fetching the communication details
                    const recipients = entitlementDetails.party.partyEmailModel[0].address;
                    const firstName = entitlementDetails.party.firstName;

                    const lastName = entitlementDetails.party.lastName;
                    const miniSiteURL = '';
                    const wirelessNumber = entitlementDetails.party.partyPhoneModel[0].number;
                    const deviceMakeandModel = entitlementDetails.contract?.contractAssetModel[0]?.contractAssetConfigurationModel?.find(c => c.contractAssetAttributeId == 2)?.value ?? "";
                    const claimNumber = entitlementDetails.claimNumber;
                    const claimSetupDate = claim?.claimSetupDate;
                    const serialNumber = entitlementDetails.contract.contractAssetModel[0].serialNumber;
                    const consumerName = firstName + ' ' + lastName;
                    const claimId = entitlementDetails.claimId;

                    let countryCode = entitlementDetails?.countryCode ?? "us";
                    let languageCode = language ?? "en";

                    let emailTypeGlobal;
                    if (countryCode.toLowerCase() == "aus") {
                        emailTypeGlobal = 45;
                    }
                    else if (countryCode.toLowerCase() == "uk") {
                        emailTypeGlobal = 46;
                    }
                    else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "en") {
                        emailTypeGlobal = 47;
                    }
                    else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "de") {
                        emailTypeGlobal = 48;
                    }
                    else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "fr") {
                        emailTypeGlobal = 118;
                    }
                    else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "en") {
                        emailTypeGlobal = 186;
                    }
                    else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "it") {
                        emailTypeGlobal = 119;
                    }
                    else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "en") {
                        emailTypeGlobal = 185;
                    }
                    else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "es") {
                        emailTypeGlobal = 120;
                    }
                    else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "en") {
                        emailTypeGlobal = 184;
                    }
                    else {
                        emailTypeGlobal = 16;
                    }

                    // sending cancel mail notification
                    fetch('api/communication/'.concat('apple'), {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({

                            emailModel: {

                                Recipients: [recipients],
                                //Recipients: [recipients, 'vakavaram@sndirect.com'], //chnage to dynamici
                                Cc: [],
                                Bcc: [],
                                IsBodyHtml: true,
                                emailType: emailTypeGlobal
                            },
                            serviceModel: {
                                FirstName: firstName,
                                LastName: lastName,
                                ConsumerName: consumerName,
                                MiniSiteURL: miniSiteURL,
                                WirelessNumber: wirelessNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
                                DeviceMakeandModel: deviceMakeandModel,

                                DocumentDeliveryMailAddress: "Claims Department, 650 Missouri Ave. Jeffersonville, IN 47130",
                                DocumentDeliveryEmailAddress: "AIGClaimsCenter@sndirect.com",
                                CallCenterTollFreeNo: '',
                                ClaimNumber: claimNumber,
                                ClaimSetupDate: moment(claimSetupDate).format("MM/DD/YYYY"),
                                SerialNumber: serialNumber,
                                DocumentRequired: "Government ID or Proof of Purchase",//this.props.DocumentRequired
                                ClaimId: parseInt(claimId),
                                WarrantyObjectTypeId: 2
                            }
                        })
                    }).then(function (res) {
                        console.log(res);
                        const statusCode = res.status;
                        return res.json();
                    })
                }

            });

        let tokenStatusCode = 0;
        let caseStatusCode = 0;

        getTUToken("apple")
            .then(function (resToken) {
                tokenStatusCode = resToken.status;
                return resToken.json();
            })
            .then(tokenData => {
                if (tokenStatusCode === 200) {
                    // Get Token
                    let token = tokenData.apiToken;

                    // Get caseId
                    getTUCaseId(claimNumber, "apple")
                        .then(function (resCase) {
                            caseStatusCode = resCase.status;
                            return resCase.json();
                        })
                        .then(caseData => {
                            if (caseStatusCode === 200) {
                                let caseId = caseData.messages[0];

                                addTUStatus(claimNumber, token, caseId, 4, "apple");
                            }
                        });

                }
            });

        let data = cancelReason.find(x => x.data === cancelOption);
        if (translations?.cancelReason1) {
            let results = (englishCancelReason.map(v => { return { data: v.claimActionReasonId, display: v.actionReasonName, type: v.claimActionTypeId } }))
            let cancelReasons = results.filter(x => x.type === 2);
            let displayText = cancelReasons.find(y => y.data === 35)?.display + ' ' + englishVars.deductibleName;
            let res = cancelReasons.map((p) => p.data === 35 ? { ...p, display: displayText } : p);
            data = res.find(x => x.data === cancelOption);
        }
        fetch(`api/claim/SaveClaimCancelReason/${clientIdCode}`,
            {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        claimId: entitlementDetails?.claimId === undefined ? location.state?.claimId : entitlementDetails?.claimId,
                        eventId: null,
                        claimActionReasonId: data?.data,
                        claimActionTypeId: data?.type,
                        userId: JSON.parse(sessionStorage.getItem("user"))?.id ?? null,
                        claimActionReasonModel:
                        {
                            actionReasonName: data?.display
                        }
                    }
                )
            }).then(res => {
                if (res.ok) {
                    if (res.status === "204") {
                        res.json().then(data => {
                            console.log(data);
                        });
                    }
                }
                else {
                    console.log(res);
                }
            })

        history.push('/vendor/apple/confirmation', { statusType: "cancelledClaim", caseNumber });
    }

    const saveForLaterAction = (e) => {
        e.preventDefault();
        onCloseModal(e)
        onSaveForLaterAction(e);
    }

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <div>
            <Modal
                isOpen={isOpen}
                toggle={onCloseModal}
                centered
                className="md"
                aria-modal="true"
                role="dialog"
                aria-labelledBy="cancel"
            >
                {(isSubmittedClaim || isUploadDocs || isClaimOverview) ? (

                    // FOR CLAIMS (APPLE) THAT HAVE ALREADY BEEN SUBMITTED.
                    <>
                        <ModalHeader toggle={onCloseModal} className="noLine" />
                        <ModalBody>
                            <h4 className="modal-title mb-4" id="cancel">
                                {translations.cancelSubmittedClaimTitle || "Are you sure you want to cancel your claim?"}
                            </h4>
                            {clientIdCode === "apple" && (
                                <FormGroup className={`mt-4 ${isCancelOptionError ? 'form-error' : ''}`}>
                                    <Label className="h6 form-label" for="cancellationReason">
                                        {translations.cancellationReason || "Cancellation Reason"}
                                        <span aria-hidden="true" className="required-indicator">
                                            *
                                        </span>
                                    </Label>
                                    <Col md="4" className={styles.cancelModalDropDown}>
                                        <DropDownInput
                                            name="states"
                                            fullWidth={true}
                                            options={translateCancelReasons()}
                                            className="form-control"
                                            onChangedValue={handleCancelReason}
                                            defaultValue={"Sample"}
                                            placeholder={translations.cancelPlaceholder || "Please select..."}
                                        />
                                    </Col>
                                    {isCancelOptionError && (
                                        <div className="errorMsg">Cancellation reason is required.</div>
                                    )}
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter className="btnWrapper">
                            <button
                                className="btnLink"
                                onClick={onResumeClick}
                                aria-label={translations.cancelSubmittedClaimKeepAria || "Click here to close this modal and keep submitted claim."}
                            >
                                {translations.cancelSubmittedClaimKeepButton || "Keep Claim"}
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={onCancelClick}
                                aria-label={translations.cancelSubmittedClaimCancelAria || "Click here to cancel your claim."}
                            >
                                {translations.cancelSubmittedClaimCancelButton || "Cancel Claim"}
                            </button>
                        </ModalFooter>
                    </>
                ) : (
                    // FOR CLAIMS BEING CANCELLED WHILE STILL IN THE FLOW
                    <>
                        <ModalBody>
                            <h1 className="h4" id="cancel">
                                {translations.title || 'Are you sure you want to cancel claim setup?'}
                            </h1>
                            <p className="mb-0">
                                {translations.warningText || 'If you do not save your claim, all information will be lost.'}
                            </p>
                            {clientIdCode === "apple" && (
                                <FormGroup className={`mt-4 ${isCancelOptionError ? 'form-error' : ''}`}>
                                    <Label className="h6 form-label" for="cancellationReason" >
                                        {translations.cancellationReason || 'Cancellation Reasons'}
                                        <span aria-hidden="true" className="required-indicator">
                                            *
                                        </span>
                                    </Label>
                                    <Col md="4" className={styles.cancelModalDropDown}>
                                        <DropDownInput
                                            name="states"
                                            fullWidth={true}
                                            options={translateCancelReasons()}
                                            className="form-control"
                                            onChangedValue={handleCancelReason}
                                            defaultValue={"Sample"}
                                            placeholder={translations.cancelPlaceholder || "Please select..."}
                                        />
                                    </Col>
                                    {isCancelOptionError && (
                                        <div className="errorMsg">Cancellation reason is required.</div>
                                    )}
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <FooterButtonsRow
                                isModal
                                buttons={{
                                    primary: {
                                        text: translations.cancelClaim || 'Cancel Claim',
                                        type: 'primary',
                                        onClick: onCancelClick,
                                        ariaLabel: translations.cancelClaimAria || 'Click to Cancel Claim',
                                    },
                                    secondary: {
                                        text: translations.resumeButton || 'Resume',
                                        type: 'secondary',
                                        onClick: onResumeClick,
                                        ariaLabel: translations.resumeClaimAria || 'Click resume claim to close modal and continue filing claim'
                                    },
                                    link: {
                                        text: translations.saveForLater || 'Save For Later',
                                        onClick: saveForLaterAction,
                                        ariaLabel: translations.saveForLaterAria || "Click to save claim to be completed at a later date",
                                    }
                                }}
                            />
                        </ModalFooter>
                    </>
                )}
            </Modal>
        </div>
    );
}

export default withRouter(CancelModal);