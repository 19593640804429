import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLayoutEffect } from 'react'
import { getTranslation, isAppleRoute } from '../../../helpers'
import { countryConstants, englishVariant } from './CountryConstants'
import { LanguageContext } from './LanguageContext'


const countryLanguageMappings = (country) => {
    switch(country) {
        case 'DE': return 'de'
        case 'ES': return 'es'
        case 'FR': return 'fr'
        case 'IT': return 'it'        
        default: return 'en'
    }
}

const langCountryMappings = (country) => {
    switch (country) {
        case 'de': return 'DE'
        case 'es': return 'ES'
        case 'fr': return 'FR'
        case 'it': return 'IT'
        case 'vn': return 'VN' 
        case 'cl': return 'ES/CL'
        case 'nl': return 'NL'
        case 'no': return 'NO'
        case 'sv': return 'SV'
        case 'da': return 'DA'
        case 'fi': return 'FI'
        case 'sg': return 'DE/SG'
        case 'at': return 'DE/AT'
        case 'ch': return 'FR'
        case 'dk': return 'DK'
        default: return 'US'
    }
}

const useTranslation = (
    componentPathFromSrcFolder = "",
    standardContentPath = ""
) => {
    const context = useContext(LanguageContext)
    const { country, language, englishVars, countryVars } = context;
    const [translations, setTranslations] = useState({})
    const [isTranslationResolved, setIsTranslationResolved] = useState(false)

    const fullPath = `customer/${langCountryMappings(language)}/${componentPathFromSrcFolder || ''}.json`
    const standardContentFullPath =  `customer/${langCountryMappings(language)}/${standardContentPath || ''}.json`

    useEffect(() => {
        const fetchMyAPI = async() => {
            setIsTranslationResolved(false)

            await getTranslation(fullPath, standardContentFullPath)
                .then(response => response.json())
                .then(data => {
                    setIsTranslationResolved(true)
                    setTranslations(data)                    
                }).catch(err => {
                    setIsTranslationResolved(true)
                })
        }
        
        fetchMyAPI()
    }, [language])

    if (translations) {
        if (!isAppleRoute()) {
            return {
                country: 'US',
                language: 'en',
                englishVars: englishVariant.american,
                countryVars: countryConstants.US,
                isTranslationResolved: true,
                translations: {},
            }
        }

        return {
            country,
            language,
            englishVars,
            countryVars,
            isTranslationResolved: language === 'en' ? true : isTranslationResolved,
            translations: language !== 'en' ? translations : {}
        }
    }
}

export default useTranslation;