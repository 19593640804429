import React from 'react';
import useTranslation from './useTranslation';

export const UseTranslationInClassComponent = ({
    componentPathFromSrcFolder, 
    standardContentPath, 
    children
}) => {
    const international = useTranslation(componentPathFromSrcFolder, standardContentPath)
    
    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return children(international)
}