import React, { useEffect } from "react"
import shortAndSweet from 'short-and-sweet';

export default function IncidentDesc(
    {
        maxLength,
        language, 
        handleChange, 
        incidentDescription, 
        charRemainingText, 
        placeHolder,
        charsRemainingPrefix,
        charsRemainingSuffix
     }
     ) {
    useEffect(() => {
        shortAndSweet('textarea', {
            counterClassName: 'my-short-and-sweet-counter',
            counterLabel: `{remaining} characters left`,
            assistDelay: 1500,
            append: (el, counter) => {
                const fragment = document.createDocumentFragment();
                const spanElement = document.createElement('p');
                spanElement.setAttribute('id', 'characterslength');
                spanElement.setAttribute('class', 'text-right');
                spanElement.innerHTML = (language?.toLowerCase() === 'vn' || language?.toLowerCase() === 'cl') ? 
                ( `${charsRemainingPrefix|| 'characters'} ${maxLength - incidentDescription.length} ${charsRemainingSuffix|| 'left'}`) : 
                (`${maxLength - incidentDescription.length} ${charRemainingText|| 'characters left'}`);
                fragment.appendChild(spanElement);
                const previousElement = document.querySelector('#characterslength');
                previousElement && previousElement.remove();
                el.parentNode.appendChild(fragment);
            }
        });
    }, [
            charsRemainingPrefix,
            charsRemainingSuffix,
            charRemainingText, 
            incidentDescription,
            language
        ])


    return (
        <textarea
            name="incidentDescription"
            type="text"
            maxLength={`${maxLength}`}
            autocomplete="off"
            onChange={handleChange}
            className="textarea form-control"
            id="incidentDescInput"
            rows="5"
            value={incidentDescription}
            spellCheck=""
            placeholder={placeHolder || "Enter a detailed description"}
            aria-required="true"
        />
    )
}