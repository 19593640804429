import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Styles from './PrivacyModal.module.css';
import FooterButtonsRow from '../../../shared/FooterButtonsRow/FooterButtonsRow';

const PrivacyModal = ({ togglePrivacyModal, showPrivacyModal }) => {
    return (
        <div>
            <div>
                <Modal isOpen={showPrivacyModal} aria-modal="true" className="md" aria-labelledBy="save" role="dialog">
                    <ModalHeader className={`noLine ${Styles.headerWrapper}`}>
                        Privacy Act 2020 Consent
                    </ModalHeader>
                    <ModalBody className={Styles.bodyWrapper}>
                        <div className='mb-3'>
                            We are committed to protecting your personal information, click to view our
                            <a href="https://www.aig.co.nz/privacy-policy#accordion-95be939873-item-89e4719713" target="_blank" className={`btnLink`}> privacy
                                policy</a>. By continuing to file your claim, you consent to AIG in accordance with the Privacy Act 2020:
                        </div>
                        <ul className="pl-0">
                            <li className='mb-3'>1. collecting, holding, and using personal information provided for purpose of administering your claim;</li>
                            <li>2. disclosing personal information submitted to another AIG company, law enforcement agencies, investigators, assessors, advisors, and the agent of any of these, or other service provider to AIG for the purpose of administering your claim, including providing a report, data management, and/or data analytics or claims recovery.</li>
                        </ul>
                        <div className='mb-3'>
                            AIG will only seek information which in its opinion it believes to be relevant to assessment of the claim. Information is provided voluntarily, however, if we do not collect this information we may not be able to assess a claim.
                        </div>
                        <div>
                            You have rights under the Privacy Act 2020 to access and correct your personal information. Further information about this or making a privacy complaint can be obtained by emailing: Privacy.officerNZ@aig.com.
                        </div>
                    </ModalBody>
                    <ModalFooter className={'pl-0 pr-0'}>
                        <FooterButtonsRow
                            isModal
                            buttons={{
                                link: {
                                    text: 'Close',
                                    type: 'primary',
                                    onClick: (e) => { togglePrivacyModal(e) },
                                    ariaLabel: 'Click resume claim to close modal and continue filing claim',
                                }
                            }}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}

export default PrivacyModal;