import React, { useState } from 'react';
import styles from './Progress.module.css';
import PageLoading from '../PageLoading/PageLoading';
import classNames from 'classnames';
import { UseTranslationInClassComponent } from '../../contexts/LanguageTranslation/UseTranslationInClassComponent';

export default function Progress({ steps, claimOverview }) {
    const trackingButtonFlag = steps.find(step => step.id === 'deliveryId')?.tracking !== null;
    const hideShipmentStepFlag = steps.find(step => step.id === 'deliveryId')?.hideStep === true;
    const [showTrackingButton, setShowTrackingButton] = useState(trackingButtonFlag);
    const [hideShipmentStep, setHideShipmentStep] = useState(hideShipmentStepFlag);
    
    return (
        <UseTranslationInClassComponent componentPathFromSrcFolder={'components/shared/StatusProgress/Progress'}>
            { international => {
                const {
                    country,
                    translations
                } = international;

                const getStatus = (status) => {
                    if (isNaN(status)){
                        switch(status.toLowerCase()){
                            case 'approved':
                                return translations.approved || "Approved";
                            case 'pending info':
                                return translations.pendingInfo || "Pending Info";
                            case 'processing':
                                return translations.processing || "Processing";
                            case 'shipped':
                                return translations.shipped || "Shipped";
                        }
                    }
                    switch(status) {
                        case 7:
                            return translations.reviewComplete || "Review Complete";
                        case 3:
                        case 15:
                        case 16:
                        case 17:
                            return translations.inReview || "In Review";
                        
                        case 2:
                            return translations.closed || "Closed";
                        case 5:
                            return translations.canceled || "Canceled";
                        case 18:
                            return translations.paymentFailed || 'Payment Failed';
            
                        default:
                            return ''
                    }
                }

                const getStatusClass = (style) => {
                    switch(style) {
                        case 'active':
                        case 'pending':
                            return styles.statusYellow;
                        case 'completed':
                            return styles.statusGreen;
                        case 'alert':
                            return styles.statusRed;
                        default:
                            return styles.statusYellow;
                    }
                }
                
                return (
                    <article className={classNames('col-lg-4', styles.rtCol)}>
                        <h2 className="h3 mb-0">{translations.title || 'Claim Status'}</h2>
                        <ul className={styles.claimProgress}>
                            {claimOverview && (
                                steps.map(step => !step.hideStep &&
                                    <li id={step.id} className={classNames(
                                        styles.progressItem, 
                                        step.style === 'completed' && styles.progressItemCompleted,
                                        step.style === 'active' && styles.progressItemActive,
                                        step.style === 'pending' && styles.progressItemPending, 
                                        step.style === 'alert' && styles.progressItemAlert
                                    )}>
                                        <h2 className={classNames('h6', 'mb-0', styles.stepName)}>{step.name}</h2>
                                        {step.status && 
                                            <p className={styles.stepSecondaryText}>
                                                {translations.status || 'Status'}:<span className={classNames(getStatusClass(step.style), 'ml-2')}>{getStatus(step.status)}</span>
                                            </p>
                                        }
                                        {step.date && 
                                            <p className={styles.stepSecondaryText}>
                                                {step.date}
                                            </p>
                                        }
                                        {step.tracking && 
                                            <p className={styles.trackingNumber}>
                                                {translations?.trackingNumber || 'Tracking #:'} {step.tracking}
                                            </p>
                                        }
                                    </li>
                                )
                            )}
                        </ul>
                        {!claimOverview && (
                                <PageLoading text={''} height={100} />
                        )}
                        {(showTrackingButton && !hideShipmentStep) &&
                            <div className='d-flex justify-content-center mt-4'>
                                <button id="trackShipBtn" className="btn btn-primary" aria-label="Opens a UPS Carrier website for tracking shipment">{translations.trackShipment || 'Track Shipment'}</button>
                            </div>
                        }
                    </article>
                )
            }}
        </UseTranslationInClassComponent>
    );
}
