import React, { Component, useContext } from 'react';
import {
    Container, Row, Col,
} from 'reactstrap';
import styles from './ConfirmationLT.module.css';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import InternationalPhoneStatic from '../../../shared/PhoneNumberUtility/InternationalPhoneStatic';
import { callAdobe } from '../../../../helpers';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent';
import { LanguageContext } from '../../../contexts/LanguageTranslation/LanguageContext';
import useTranslation from '../../../contexts/LanguageTranslation/useTranslation';
import CallLink from '../../../shared/PhoneNumberUtility/CallLink';
import { appleServiceNumbers } from '../../../_BrandingProvider/brandContent';
import { ContractContext } from '../../../contexts/ContractContext';
import { CombinedLangContractContext } from '../../../contexts/ProviderLangContractContext';
import SBCompleteIcon from '../../../../assets/images/Vendor/SB/claim-complete.svg';
import ClaimCompleteIcon from '../../../../assets/images/claim-complete.svg';
import SBReviewIcon from '../../../../assets/images/Vendor/SB/claim-review.svg';
import ClaimReviewIcon from '../../../../assets/images/claim-review.svg';
import SBSavedIcon from '../../../../assets/images/Vendor/SB/claim-saved.svg';
import ClaimSavedIcon from '../../../../assets/images/claim-saved.svg';
import SBCancelledIcon from '../../../../assets/images/Vendor/SB/claim-cancelled.svg';
import ClaimCancelledIcon from '../../../../assets/images/claim-cancelled.svg';

let btnPrimary = "btn-primary";
let mt18 = styles.mt18;

export const StandardContent = ({
    customClass = '',
    caseNumber, 
    claimEmail, 
    countryCode = '', 
    standardContentOption = '' 
}) => {
    const international = useTranslation(
        'components/Vendor/Apple/IntakeLoss/ConfirmationLT', 
        'components/shared/StandardContent/StandardContent'
    )
    const country = international?.country || countryCode?.toUpperCase()
    const { translations, language, englishVars } = international
    const localPhone = appleServiceNumbers.customerService[country]
    const deductible = englishVars.deductibleName?.toLowerCase();

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

        switch(standardContentOption) {
            case 'agentReview':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.agentReview_standard || 'Once one of our representatives has reviewed the information provided, you\'ll be notified of the outcome by email. Please allow up to 2 business days for our team to review and process.'}
                    </p>
                )
            case 'affirmation':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.affirmation_standard || 'You affirm that the information you have provided regarding your claim is true and accurate. You understand that if you knowingly and intentionally provide falst or untrue information regarding your claim, it may be considered fraud.'}
                    </p>
                )
            case 'communicationUpdates':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.commUpdatePrefix_standard || 'Claim status updates will be emailed to '}
                        {claimEmail}
                        {translations.standardContent?.commUpdateSuffix_standard || '. To ensure you receive updates about your claim, please add aigtheftandloss@aig.com to your email contacts or safe list.'}
                    </p>
                )
            case 'contactCustomerService':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.contactCustomerServicePrefix_standard || 'For concerns regarding your claim, contact '}
                        <CallLink phone={localPhone} />
                        {translations.standardContent?.contactCustomerServiceSuffix_standard || ' referencing Case ID: '}
                        {caseNumber}
                    </p>
                )
            case 'contactTwoDays':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.contactTwoDays_standard || 'We will contact you within 2 business days with details about your claim.'}
                    </p>
                )
            case 'failureToProvide':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.failureToProvide_standard || 'Failure to provide the requested information within 30 days may result in your claim being closed due to inactivity.'} 
                    </p>
                )
            case 'pendingAuth':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.pendingAuth_standard || `The ${deductible} will appear as a pending authorization on your credit card. Once the claim is approved, the payment will be processed. `}
                    </p>
                )
            case 'replacement':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.replacement_standard || 'You will receive a replacement device within 1 to 3 business days.'}
                    </p>
                )
            case 'shippingCommunicationUpdate':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.shippingCommUpdatePrefix_standard || 'Shipment tracking information and claim status updates will be emailed to '}
                        {claimEmail}
                        {translations.standardContent?.shippingCommUpdateSuffix_standard || '. To ensure you receive updates about your claim, please add aigtheftandloss@aig.com to your email contacts or safe list.'}
                    </p>
                )
            case 'unlockDevice':
                return (
                    <p className={`${customClass}`}>
                        {translations.standardContent?.unlockDevicePhonePrefix_standard || 'Please allow 48 hours for your device to unlock. If your device is still locked 48 hours after your claim has been canceled, please contact us at '}
                        <CallLink phone={localPhone} />
                        {translations.standardContent?.unlockDeviceCasePrefix_standard || '. Please reference your Case ID '}
                        {caseNumber}
                        {translations.standardContent?.unlockDeviceCaseSuffix_standard || ' so that we may quickly access your request.'}
                    </p>
                )
            default:
                return (
                    <p className={`${customClass}`}>
                        Standard content not found.
                    </p>
                )
        }

}

export const ToSpeakWithARepresentative = ({ caseNumber, countryCode = '' }) => {
    const international = useTranslation('components/Vendor/Apple/IntakeLoss/ConfirmationLT')
    const country = countryCode.toUpperCase() || international.country
    const { translations, language } = international

    const localPhone = appleServiceNumbers.customerService[country]

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <p>
            {translations.representative1 || 'If you\’d like to speak with one of our representatives, call '}
            <CallLink phone={localPhone} />
            {`${language === 'sg' || language === 'at' ? '' : '.'} ${translations.representative2 || 'Please reference your Case ID'}: ${caseNumber}.`}
        </p>

    )
}



export class ConfirmationLT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusType: this.props.statusType,
            contractNumber: "",  // case number
            client: this.props.clientId,
            claimId: this.props.claimId,
            claimNumber: this.props.claimNumber,
            claimEmail: "",
            caseNumber: this.props.caseNumber,
            isDocUploaded: this.props.isDocUploaded,
            pageTitle: 'Confirmation | AIG AppleCare+ with Theft and Loss Claims',
            ccNumber: "",
            ccType: ""
        }

        this.viewClaim = this.viewClaim.bind(this);
        this.viewClaimReview = this.viewClaimReview.bind(this);
    }

    componentDidMount() {

        const currentPathname = this.props.history?.location?.pathname;

        if (currentPathname === '/vendor/apple/confirmation') {
            window.history.pushState(this.props.history?.location, document.title, window.location.href);
            window.scrollTo(0, 0);
        }

        if (typeof this.props.location.state != "undefined") {
            this.setState(() => ({
                statusType: this.props.location.state.statusType == undefined ? ConfirmationStatus.docsPending : this.props.location.state.statusType,
                contractNumber: this.props.contractNumber == undefined ? "230404953" : this.props.contractNumber,
                client: this.props.client == undefined ? "apple" : this.props.client,
                claimNumber: this.props.location.state.claimNumber,
                claimEmail: this.props.location.state.claimEmail || this.props?.history?.location?.state?.entitlementDetails?.email || "",
                caseNumber: this.props.location.state.caseNumber,
                claimId: this.props.location.state.claimId,
                ccNumber: this.props.location.state.ccNumber,
                ccType: this.props.location.state.ccType
            }));
        }

        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: 'vendor/apple/confirmation',
            pageName: 'Confirmation',
            country: this.context.languageContext?.country,
            language: this.context.languageContext?.language,
            currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
            caseId: this.props.location?.state?.caseNumber,
            claimStatus: this.props?.location?.state?.statusType == undefined ? ConfirmationStatus?.docsPending : this.props.location?.state?.statusType
        })
    }

    viewClaim = e => {
        e.preventDefault();
        const url = "/vendor/apple/claimoverview/";
        this.props.history.push(url, { isDocUploaded: false, caseNumber: this.state.caseNumber, ccNumber: this.state.ccNumber, ccType: this.state.ccType });
    }
    viewClaimReview = e => {
        e.preventDefault();
        const url = "/vendor/apple/claimoverview/";
        this.props.history.push(url, {
            isDocUploaded: true, isUnderReview: true, isValidClaim: false, claimId: this.state.claimId, caseNumber: this.state.caseNumber,
            ccNumber: this.state.ccNumber, ccType: this.state.ccType, isDeductibleUnsuccessful: true, isPendingReview: true, isClaimDenied: false
        });
    }

    render() {
        const { pageTitle } = this.state;
        const { contractContext: { data: { isChileApple } } } = this.context;
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/IntakeLoss/ConfirmationLT'}>
                {international => {
                    const {
                        country,
                        translations,
                        countryVars,
                        countryVars: {
                            currency
                        },
                        englishVars: {
                            confirmScreen,
                            deductibleName
                        }
                    } = international;
                    return (
                        <DocumentTitle title={pageTitle}>
                            <Container>
                                {(() => {
                                    switch (this.state.statusType) {

                                        case "approvedClaim":
                                            // FOR ADOBE ANALYTICS
                                            callAdobe({
                                                pageName: 'Confirmation Successful',
                                                pagePath: 'vendor/apple/confirmation/successful'
                                            })
                                            return (
                                                <section id="approvedClaim" className={styles.confirmSection}>
                                                    <Row>
                                                        <Col className={styles.confirmWidthWrap}>
                                                            <img
                                                                className={styles.confirmIcon}
                                                                role="presentation"
                                                                src={
                                                                    isChileApple
                                                                        ? SBCompleteIcon
                                                                        : ClaimCompleteIcon
                                                                }
                                                            />
                                                            <h1 className="h3">{translations?.approvedClaim?.title || 'Your Claim is Complete'}</h1>
                                                            <p className='mb-0'>{translations?.approvedClaim?.p1 || 'Thank you for your payment.'}</p>
                                                            <StandardContent standardContentOption='replacement'/>
                                                            <StandardContent 
                                                                claimEmail={this.state.claimEmail} 
                                                                standardContentOption='shippingCommunicationUpdate'
                                                            />
                                                            <button
                                                                className="btnLink"
                                                                onClick={() => this.props.history.push("/vendor/apple/claimoverview/", { isDocUploaded: true, isUnderReview: false, isValidClaim: true, claimId: this.state.claimId, caseNumber: this.state.caseNumber, ccNumber: this.state.ccNumber, ccType: this.state.ccType, isDeductibleUnsuccessful: false, isPendingReview: false, isClaimDenied: false })}
                                                                aria-label={translations.viewClaimButtonAria || "Click to view a summary of your claim"}
                                                            >
                                                                {translations?.viewClaimButton || 'View Claim Details'}
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </section>
                                            )

                                        case "claimInReview":
                                            // FOR ADOBE ANALYTICS
                                            callAdobe({
                                                pageName: 'Confirmation In Review',
                                                pagePath: 'vendor/apple/confirmation/inreview'
                                            })
                                            return (
                                                <section id="claimInReview" className={styles.confirmSection}>
                                                    <Row>
                                                        <Col className={styles.confirmWidthWrap}>
                                                            <img
                                                                className={styles.confirmIcon}
                                                                role="presentation"
                                                                src={
                                                                    isChileApple
                                                                        ? SBReviewIcon
                                                                        : ClaimReviewIcon
                                                                }
                                                            />
                                                            <h1 className="h3">{translations?.claimInReview?.title || 'Your Claim is in Review'}</h1>
                                                            <StandardContent standardContentOption='contactTwoDays'/>
                                                            <StandardContent standardContentOption='pendingAuth'/>
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                claimEmail={this.state.claimEmail}
                                                                standardContentOption='communicationUpdates'
                                                            />
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                standardContentOption='contactCustomerService'
                                                            />
                                                            <button
                                                                className="btnLink"
                                                                onClick={() => this.props.history.push("/vendor/apple/claimoverview/", {
                                                                    isDocUploaded: true,
                                                                    isUnderReview: false,
                                                                    isValidClaim: false,
                                                                    claimId: this.state.claimId,
                                                                    caseNumber: this.state.caseNumber,
                                                                    ccNumber: this.state.ccNumber,
                                                                    ccType: this.state.ccType,
                                                                    isDeductibleUnsuccessful: true,
                                                                    isPendingReview: true,
                                                                    isClaimDenied: false
                                                                })}
                                                                aria-label={translations.viewClaimButtonAria || "Click to view a summary of your claim"}
                                                            >
                                                                {translations?.viewClaimButton || 'View Claim Details'}
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </section>
                                            )


                                        case "savedClaim":
                                            // FOR ADOBE ANALYTICS
                                            callAdobe({
                                                pageName: 'Confirmation Saved Claim',
                                                pagePath: 'vendor/apple/confirmation/savedclaim'
                                            })
                                            return (
                                                <section id="savedClaim" className={styles.confirmSection}>
                                                    <Row>
                                                        <Col className={styles.confirmWidthWrap}>
                                                            <img
                                                                className={styles.confirmIcon}
                                                                role="presentation"
                                                                src={
                                                                    isChileApple
                                                                        ? SBSavedIcon
                                                                        : ClaimSavedIcon
                                                                }
                                                            />
                                                            <h1 className="h3">{translations?.savedClaim?.title || 'Your Claim has been Saved'}</h1>
                                                            <p className='mb-3'>{translations?.savedClaim?.p1 || 'You will receive an email with a link to resume your claim.'}</p>
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                claimEmail={this.state.claimEmail}
                                                                standardContentOption='communicationUpdates'
                                                            />
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                standardContentOption='contactCustomerService'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </section>
                                            )


                                        case "docsUploaded":
                                            // FOR ADOBE ANALYTICS
                                            callAdobe({
                                                pageName: 'Confirmation Docs Uploaded',
                                                pagePath: 'vendor/apple/confirmation/docsuploaded'
                                            })
                                            return (
                                                <section id="docsUploaded" className={styles.confirmSection}>
                                                    <Row>
                                                        <Col className={styles.confirmWidthWrap}>
                                                            <img
                                                                className={styles.confirmIcon}
                                                                role="presentation"
                                                                src={
                                                                    isChileApple
                                                                        ? SBReviewIcon
                                                                        : ClaimReviewIcon
                                                                }
                                                            />
                                                            <h1 className="h3">{translations?.docsUploaded?.title || 'Your Claim is in Review'}</h1>
                                                            <p className='mb-0'>
                                                                {translations?.docsUploaded?.p1 || 'Thank you for uploading your document(s).'}
                                                            </p>
                                                            <StandardContent standardContentOption='contactTwoDays'/>
                                                            <StandardContent standardContentOption='pendingAuth'/>
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                claimEmail={this.state.claimEmail}
                                                                standardContentOption='communicationUpdates'
                                                            />
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                standardContentOption='contactCustomerService'
                                                            />
                                                            <button
                                                                className="btnLink"
                                                                onClick={this.viewClaimReview}
                                                                aria-label={translations?.viewClaimButtonAria || 'Click to view a summary of your claim'}
                                                            >
                                                                {translations?.viewClaimButton || 'View Claim Details'}
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </section>
                                            )


                                        case "docsPending":
                                            // FOR ADOBE ANALYTICS
                                            callAdobe({
                                                pageName: 'Confirmation Docs Required',
                                                pagePath: 'vendor/apple/confirmation/docsrequired'
                                            })
                                            return (
                                                <section id="docsPending" className={styles.confirmSection}>
                                                    <Row>
                                                        <Col className={styles.confirmWidthWrap}>
                                                            <img
                                                                className={styles.confirmIcon}
                                                                role="presentation"
                                                                src={
                                                                    isChileApple
                                                                        ? SBReviewIcon
                                                                        : ClaimReviewIcon
                                                                }

                                                            />
                                                            <h1 className="h3">{translations?.docsPending?.title || 'Your Claim is Pending Documents'}</h1>
                                                            <p className='mb-0'>{translations?.docsPending?.p1Bold || 'Once you have uploaded the required documents, your claim will be processed.'}</p>
                                                            <StandardContent standardContentOption='failureToProvide'/>
                                                            <StandardContent standardContentOption='pendingAuth'/>
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                claimEmail={this.state.claimEmail}
                                                                standardContentOption='communicationUpdates'
                                                            />
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                standardContentOption='contactCustomerService'
                                                            />
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <button
                                                                    className={classNames("btn", btnPrimary, mt18)}
                                                                    onClick={() => this.props.history.push("/vendor/apple/claimoverview/",
                                                                        {
                                                                            isFraud: true,
                                                                            isDocUploaded: false,
                                                                            isUnderReview: false,
                                                                            isValidClaim: false,
                                                                            claimId: this.state.claimId,
                                                                            caseNumber: this.state.caseNumber,
                                                                            ccType: this.state.ccType,
                                                                            ccNumber: this.state.ccNumber,
                                                                            claimNumber: this.props.history.location.state.claimNumber,
                                                                            entitlementDetails: this.props.history.location.state.entitlementDetails,
                                                                            isDeductibleUnsuccessful: true,
                                                                            isPendingReview: true,
                                                                            isClaimDenied: false
                                                                        })
                                                                    }
                                                                    aria-label={translations?.docsPending?.uploadBtnAria || 'Click to upload documents and view a summary of your claim'}
                                                                >
                                                                    {translations?.docsPending?.button || 'Upload Documents'}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </section>
                                            )


                                        case "cancelledClaim":
                                            // FOR ADOBE ANALYTICS
                                            callAdobe({
                                                pageName: 'Confirmation Cancel Claim',
                                                pagePath: 'vendor/apple/confirmation/cancelclaim'
                                            })
                                            return (
                                                <section id="cancelledClaim" className={styles.confirmSection}>
                                                    <Row>
                                                        <Col className={styles.confirmWidthWrap}>
                                                            <img
                                                                className={styles.confirmIcon}
                                                                alt="Claim Cancelled Icon"
                                                                src={
                                                                    isChileApple
                                                                        ? SBCancelledIcon
                                                                        : ClaimCancelledIcon
                                                                }
                                                            />
                                                            <h1 className="h3">{translations?.cancelledClaim?.title || 'Your Claim has been Canceled'}</h1>
                                                            <StandardContent caseNumber={this.state.caseNumber} standardContentOption='unlockDevice'/>
                                                            <StandardContent 
                                                                caseNumber={this.state.caseNumber} 
                                                                standardContentOption='contactCustomerService'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </section>
                                            )

                                        case "customerSurvey":
                                            // FOR ADOBE ANALYTICS
                                            callAdobe({
                                                pageName: 'Confirmation Customer Survey',
                                                pagePath: 'vendor/apple/confirmation/customersurvey'
                                            })
                                            return (
                                                <section id="customerSurvey" className={styles.confirmSection}>
                                                    <Row>
                                                        <Col className={styles.confirmWidthWrap}>
                                                            <img
                                                                className={styles.confirmIcon}
                                                                role="presentation"
                                                                src={
                                                                    isChileApple
                                                                        ? SBSavedIcon
                                                                        : ClaimSavedIcon
                                                                }
                                                            />
                                                            <h1 className="h3">{translations?.customerSurvey?.title || 'Thank you for your time and feedback.'}</h1>
                                                            <p>{translations?.customerSurvey?.p1 || 'Your opinion is important to us. We are always looking for ways to improve your service experience.'}</p>
                                                        </Col>
                                                    </Row>
                                                </section>
                                            )

                                    }
                                })()}
                            </Container>
                        </DocumentTitle>
                    );
                }
                }
            </UseTranslationInClassComponent>
        );
    }
}
ConfirmationLT.contextType = CombinedLangContractContext

export class ConfirmationStatus {

    static cancel = 'cancelledClaim';
    static approved = 'approvedClaim';
    static inReview = 'claimInReview';
    static saved = 'savedClaim';
    static docsUploaded = 'docsUploaded';
    static docsPending = 'docsPending';
}