import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useTranslation from '../contexts/LanguageTranslation/useTranslation';
import { ContractContext } from '../contexts/ContractContext';
import AIGLogo from '../../assets/images/aig-logo.svg';
import SouthBridgeLogo from '../../assets/images/Vendor/SB/southbridge-logo.png';
import SaveModal from './SaveModal';
import styles from '../Vendor/Apple/Header.module.css';


const LogoModal = () => {
    const history = useHistory();
    const location = useLocation();
    const international = useTranslation('components/modals/logomodal')
    const { translations } = international
    const contractContext = useContext(ContractContext);
    const { data: { isChileApple } } = contractContext;
    const [logoSrc, setLogoSrc] = useState();
    const [isSaveForLater, setIsSaveForLater] = useState(false);
    const [showSaveClaimModal, setShowSaveClaimModal] = useState(false);
    const [isLogoInteractive, setIsLogoInteractive] = useState(true);
    const currentPathname = location.pathname || '';
    const claimStatus = history.location?.state?.statusType || '';
    const claimStatusId = history.location?.state?.claim?.claimStatusId;


    useEffect(() => {

        if (currentPathname === '/vendor/apple' ||
            (claimStatusId === 1 && currentPathname === '/vendor/apple/payment') ||
            currentPathname?.toLowerCase().includes('/vendor/apple/claimoverview')
        ) {
            setIsLogoInteractive(false);
        }
        else {
            setIsLogoInteractive(true);
        }
    }, [currentPathname])


    const handleLogoClick = (e) => {
        e.preventDefault();
        document.getElementById('logo-button').focus()

        if ((currentPathname === '/vendor/apple/entitlement' ||
            currentPathname === '/vendor/apple/verifyinfo' ||
            currentPathname === '/vendor/apple/payment') &&
            (claimStatusId === 3 || claimStatusId === 6 || claimStatusId === 8)
        ) {
            setShowSaveClaimModal(true);
        }
        else if (claimStatusId === 1 && currentPathname === '/vendor/apple/payment') {

            setShowSaveClaimModal(false);
        }
        else if (currentPathname?.toLowerCase().includes('/vendor/apple/uploaddocs')) {

            history.push('/vendor/apple/claimoverview/', {
                isFraud: true,
                isDocUploaded: false,
                isUnderReview: false,
                isValidClaim: false,
                claimId: history.location?.state?.claimId,
                caseNumber: history.location?.state?.caseNumber,
                ccType: history.location?.state?.ccType || '',
                ccNumber: history.location?.state?.ccNumber || '',
                claimNumber: history.location.state.claimNumber,
                entitlementDetails: history.location.state.entitlementDetails,
                isDeductibleUnsuccessful: true,
                isPendingReview: true,
                isClaimDenied: false
            })
        }
        else if (currentPathname === '/vendor/apple/confirmation') {
            const url = '/vendor/apple/claimoverview/';

            if (claimStatus === 'approvedClaim') {

                history.push(url, {
                    isDocUploaded: true,
                    isUnderReview: false,
                    isValidClaim: true,
                    claimId: history.location?.state?.claimId,
                    caseNumber: history.location?.state?.caseNumber,
                    ccNumber: history.location?.state?.ccNumber || '',
                    ccType: history.location?.state?.ccType || '',
                    isDeductibleUnsuccessful: false,
                    isPendingReview: false,
                    isClaimDenied: false
                })
            }
            else if (claimStatus === 'claimInReview') {

                history.push(url, {
                    isDocUploaded: true,
                    isUnderReview: false,
                    isValidClaim: false,
                    claimId: history.location?.state?.claimId,
                    caseNumber: history.location?.state?.caseNumber,
                    ccNumber: history.location?.state?.ccNumber || '',
                    ccType: history.location?.state?.ccType || '',
                    isDeductibleUnsuccessful: true,
                    isPendingReview: true,
                    isClaimDenied: false
                })
            }
            else if (claimStatus === 'docsUploaded') {

                history.push(url, {
                    isDocUploaded: true,
                    isUnderReview: true,
                    isValidClaim: false,
                    claimId: history.location?.state?.claimId,
                    caseNumber: history.location?.state?.caseNumber,
                    ccNumber: history.location?.state?.ccNumber || '',
                    ccType: history.location?.state?.ccType || '',
                    isDeductibleUnsuccessful: true,
                    isPendingReview: true,
                    isClaimDenied: false
                })
            }
            else if (claimStatus === 'docsPending') {

                history.push(url, {
                    isFraud: true,
                    isDocUploaded: false,
                    isUnderReview: false,
                    isValidClaim: false,
                    claimId: history.location?.state?.claimId,
                    caseNumber: history.location?.state?.caseNumber,
                    ccType: history.location?.state?.ccType || '',
                    ccNumber: history.location?.state?.ccNumber || '',
                    claimNumber: history.location.state.claimNumber,
                    entitlementDetails: history.location.state.entitlementDetails,
                    isDeductibleUnsuccessful: true,
                    isPendingReview: true,
                    isClaimDenied: false
                })
            }
            else if (claimStatus === 'savedClaim') {

                history.push('/vendor/apple', { showSearchPage: true });
            }
        }
        else if (currentPathname === '/vendor/apple/faq' ||
            currentPathname === '/vendor/apple/coveragedocs' ||
            currentPathname === '/vendor/apple/contactus' ||
            currentPathname === '/vendor/apple/programsummarydisclosures' ||
            currentPathname?.toLowerCase().includes('/vendor/apple/contactconfirmation') ||
            currentPathname?.toLowerCase().includes('/vendor/apple/econsent')) {

            history.push('/vendor/apple', { showSearchPage: true });
        }
    };

    const onSaveCancel = (e) => {
        e.preventDefault();
        setShowSaveClaimModal(false);
    }

    const saveForLater = (e) => {
        e && e.preventDefault();
        setIsSaveForLater(true)
        saveClaim(history?.location?.state?.claim, false);
    }

    const saveClaim = (claim, claimAbandoned) => {

        let step = 0;

        if (currentPathname === '/vendor/apple/entitlement') {
            step = 1;
        }
        else if (currentPathname === '/vendor/apple/verifyinfo') {
            step = 2;
        }
        else if (currentPathname === '/vendor/apple/payment') {
            step = 3;
        }

        if (!claim || step === 0) { return; }

        fetch(`api/claim/SaveForLater/${claim?.claimId}/apple/${step}/${claimAbandoned}`, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(claim)

        }).then((data) => {
            if (data.status === 204) {
            }
            if (data.status === 200 && !claimAbandoned) {
                history.push('/vendor/apple/confirmation', { statusType: 'savedClaim', caseNumber: claim.clientClaimNumber });
            }
        });
    }

    useEffect(() => {
        if (isChileApple) {
            setLogoSrc(SouthBridgeLogo);
        } else {
            setLogoSrc(AIGLogo);
        }
    }, [isChileApple])

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <div>
            <div onClick={handleLogoClick} role='button' tabIndex='0' id='logo-button' className={!isLogoInteractive && styles.logoBtn}>
                <img
                    className='logo'
                    src={logoSrc}
                    alt='AIG Home'
                />
            </div>
            {showSaveClaimModal && (
                <SaveModal
                    aria-label={translations.saveBtnAria || 'Opens a modal to save your claim'}
                    showConfirmation={showSaveClaimModal}
                    continuing={isSaveForLater}
                    onCancel={onSaveCancel}
                    onSaveForLaterAction={saveForLater}
                    client={'apple'}
                />
            )}
        </div>
    );
}

export default withRouter(LogoModal);