import React, { useState, useEffect, useContext } from 'react';
import styles from './ContactConfirmation.module.css';
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../contexts/LanguageTranslation/useTranslation';
import { StandardContent } from '../IntakeLoss/ConfirmationLT';
import { ContractContext } from '../../../contexts/ContractContext';
import { useAdobeAnalytics } from '../../../hooks/useAdobeAnalytics';
import AppleServicerIcon from '../../../../assets/images/Vendor/Apple/Servicer_Icon.svg'
import SBServicerIcon from '../../../../assets/images/Vendor/SB/Servicer_Icon.svg';

export const ContactConfirmation = (props) => {
  const history = useHistory();
  const international = useTranslation('components/Vendor/Apple/Contact/ContactConfirmation')
  const { translations } = international
  const { data: { isChileApple } } = useContext(ContractContext);
  const [servicerIconSrc, setServicerIconSrc] = useState(AppleServicerIcon);
  const { callAdobeAnalyticsData } = useAdobeAnalytics();

  useEffect(() => {
    // FOR ADOBE ANALYTICS
    callAdobeAnalyticsData({
      pagePath: '/vendor/apple/contactconfirmation',
      pageName: 'Contact Us Confirmation',
      caseId: history?.location?.state?.caseId
    })
  }, []);

    useEffect(() => {
        if (isChileApple) {
            setServicerIconSrc(SBServicerIcon);
        } else {
            setServicerIconSrc(AppleServicerIcon);
        }
    }, [isChileApple])

  // block component rendering until translation service call resolves translations
  if (!international.isTranslationResolved) return null

  return (
      <div>
        <section className={styles.confirmSection}>
          <img className={styles.confirmIcon} src={servicerIconSrc}></img>
          <h1 className="h3">{translations.title || "We’ll Contact You Soon"}</h1>
          <p className='mb-3'>{translations.p1 || "We will contact you within 1 business day about your request."}</p>
          <StandardContent 
              caseNumber={history?.location?.state?.caseId} 
              standardContentOption='contactCustomerService'
          />
        </section>
      </div>
  )
}